<template lang="html">
  <section class="new-featured-vertical-text" v-if="dataContent">
    <router-link
      :to="{
        name: 'page-entry',
        params: {
        typeContent: this.dataContent.type,
          slug: this.dataContent.slug,
          typeMedia: this.typeMedia,
        },
      }"
      class="content-new"
      title="Enlace a la noticia"
    >
      <div class="info-box">
        <!-- <span class="category">ÚLTIMA PUBLICACIÓN</span> -->
        <h2 class="title">
        {{ dataContent.name }}
        </h2>
         <p class="box-dates">
          <span class="date-text">{{ currentDateTime(dataContent.publish_date) }} </span>
          <span class="author" v-if="dataContent.source">{{dataContent.source}}</span>
        
        <template v-for="theme in dataContent.themes" :key="theme.id">
          <span class="category-tag">{{ theme.name }}</span>
        </template>
<span class="category-location" v-if="dataContent.zone">{{
          dataContent.zone.name
        }}</span>
</p>
<p  >
    {{cutText(dataContent.header ,100) }}
</p>
</div>
</router-link>
</section>
</template>

<script lang="js">
    import moment from 'moment'
    export default {
        name: 'new-featured-vertical-text',
        props: ['dataContent'],
        mounted() {

        },
        data() {
            return {

            }
        },
        methods: {
            currentDateTime(date) {
                return moment(date).locale('es').format('L')
            },

        },
        computed: {
            typeContent() {
                switch (this.dataContent.type) {
                    case 'entry':
                        return 'noticia'
                    case 'opinion':
                        return 'opinion'
                    case 'interview':
                        return 'entrevista'
                    case 'coverage':
                        return 'reportaje'
                    case 'event':
                        return 'evento'
                    case 'media':
                        return 'multimedia'
                    default:
                        return 'noticia'
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "@/styles/colours.scss";
    .new-featured-vertical-text {
        cursor: pointer;
        .content-new {
            text-decoration: none;
            display: flex;
            flex-wrap: wrap;
            overflow: hidden;
            position: relative;
            border-radius: 13px;
            padding-left: 10px;
            margin-bottom: 20px;
            .category {
                background: $blue;
                font-weight: 900;
                color: white;
                font-size: 16px;
                padding: 5px 15px;
                border-radius: 13px;
                z-index: 0;
                margin-bottom: 10px;
                display: inline-block;
            }
            .img-new {
                width: 100%;
                border-radius: 13px;
                height: 230px;
                object-fit: cover;
                object-position: center;
            }
            .info-box {
                width: 100%;
                padding: 20px 0px;
            }
        }
    }
    @media screen and (max-width:845px) {
       .new-featured-vertical-text .content-new{
            padding-left: 0;
        }
    }
</style>