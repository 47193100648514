<template lang="html" v-if="dataContent">
    <section class="banner-suscription" :style="`background-image:url(` + dataContent.image.url + `)`">
        <div class="container">
            <p class="title-dest">{{ dataContent.title }}</p>
            <p>{{ dataContent.description }}</p>
            <a :href="dataContent.url" to="{'name': 'suscription'}" class="btn-white" title="Ir a página de suscrición">{{ dataContent.button_text }}</a>
        </div>
    </section>
</template>

<script lang="js">
export default {
    name: 'banner-suscription',
    props: ['dataContent'],
}
</script>

<style scoped lang="scss">
.banner-suscription {
    background-image: url("/img/img-banner-suscription.jpg");
    background-size: cover;
    background-position: center center;
    margin: 50px 0 20px 0;
    
    .container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
        min-height: 330px;
        max-width: 900px;
        text-align: center;
        p {
            color: white;
        }
        .btn-white {
            margin-top: 30px;
        }
    }
}
</style>