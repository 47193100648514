<template lang="html">
    <section class="new-only-text grid" v-if="dataContent">
        <router-link :to="{
        name: 'page-entry',
        params: {
          typeContent: this.dataContent.type,
          slug: this.dataContent.slug,
        },
      }" class="content-new" title="Enlace a la noticia">
            <div class="info-box">
                <h2 class="title">
                    {{ dataContent.name }}
                </h2>
                <p class="box-dates">
                    <span class="date-text">{{ currentDateTime(dataContent.publish_date) }} </span>
                    <span class="author" v-if="dataContent.source">{{dataContent.source}}</span>

                    <template v-for="theme in dataContent.themes" :key="theme.id">
                        <span class="category-tag">{{ theme.name }}</span>
                    </template>
                    <span class="category-location" v-if="dataContent.zone">{{
                        dataContent.zone.name
                        }}</span>
                </p>
                <p  >
                    {{ cutText(dataContent.header , 100) }}
                </p>
            </div>
        </router-link>
    </section>
</template>

<script lang="js">
    import moment from 'moment'

    export default {
        name: 'new-only-text',
        props: ['dataContent'],
      
        mounted() {

        },
        data() {
            return {

            }
        },
        methods: {
            currentDateTime(date) {
                return moment(date).locale('es').format('L')
            },


        },
        computed: {
            typeContent() {
                switch (this.dataContent.type) {
                    case 'entry':
                        return 'noticia'
                    case 'opinion':
                        return 'opinion'
                    case 'interview':
                        return 'entrevista'
                    case 'coverage':
                        return 'reportaje'
                    case 'event':
                        return 'evento'
                    case 'media':
                        return 'multimedia'
                    default:
                        return 'noticia'
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "@/styles/colours.scss";

    .new-only-text {
        cursor: pointer;
        border-top: 1px solid $blueLight;

        .content-new {
            text-decoration: none;
            display: flex;
            flex-wrap: wrap;
            overflow: hidden;
            height: 100%;
            /* max-height: 300px; */
            position: relative;
            border-radius: 13px;
            padding-left: 10px;

            .info-box {
                width: 100%;
                padding: 20px 0px;
            }
        }
    }
    @media screen and (max-width:845px) {
        .new-only-text .content-new{
            padding-left: 0;
        }
    }
</style>