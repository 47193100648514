<template lang="html">
    <section  v-if="featured && featured.length != 0" v-bind:class="'slider-news-featured ' + this.cantidad()">
        <agile :options="myOptions" ref="carousel">
            <div class="slide" v-for="(notice, key) in featured" :key="key">
                <router-link :to="{
                        name: 'page-entry',
                        params: {
                            typeContent: notice.type,
                            slug: notice.slug
                        }
                    }" class="content-new" title="Enlace a la noticia">
                    <img :src="notice.image.url" :alt="notice.image_footer" class="img-new" />
                    <template v-for="theme in notice.themes" :key="theme.id">
                        <span v-if="theme.special == 1" class="category">{{ theme.name }}</span>
                    </template>
                    <div class="info-box">
                        <h2><Markdown v-if="notice.name_markdown" class="title" :source="notice.name_markdown" /></h2>
                        <!-- <p class="title">{{ notice.name }}</p> -->
                        <p class="date-text">
                            {{ currentDateTime(notice.publish_date) }}
                            <span class="author" v-if="notice.interviewed">{{ notice.interviewed }}</span>
                            <span class="author" v-if="notice.source">{{ notice.source }}</span>
                        </p>
                        <p>{{notice.header}}</p>
                    </div>
                </router-link>
            </div>
        </agile>
    </section>
</template>

<script lang="js">
    import Markdown from 'vue3-markdown-it';
    import {
        mapActions,
        mapGetters,
    } from "vuex";
    import {
        VueAgile
    } from "vue-agile";
    import moment from 'moment'
    export default {
        name: 'slider-news-featured',
        components: {
            agile: VueAgile,
            Markdown
        },
        data: () => ({
          
            myOptions: {
                responsive: [{
                    breakpoint: 0,
                    settings: {
                        fade: true,
                        navButtons: true,
                        slidesToShow: 1,
                        dots: false,
                    },
                }],
            },
        }),
        computed: {
            ...mapGetters({
                featured: 'contents/getFeatured',
            })
        },
        methods: {
            ...mapActions({
                loadFeatured: 'contents/loadFeatured',
            }),
            currentDateTime(date) {
                return moment(date).locale('es').format('L')
            },
            cantidad(){
                if(this.featured.length<=1){
                    return 'no'
                }else{
                    return 'yes'
                }
            },
        },
        mounted() {
            this.loadFeatured();
        }
    }
</script>

<style scoped lang="scss">
    @import "@/styles/colours.scss";

    .slider-news-featured {
        margin-bottom: 40px;

        .content-new {
            cursor: pointer;
            text-decoration: none;
            display: flex;
            flex-wrap: wrap;
            overflow: hidden;
            height: 100%;
            /* max-height: 300px; */
            position: relative;
            border-radius: 13px;
            padding-left: 10px;

            .category {
                position: absolute;
                left: 0px;
                top: 10px;
                z-index: 2;
                background: $orange;
                z-index: 0;
                font-weight: 900;
                color: white;
                font-size: 16px;
                padding: 5px 15px;
                border-radius: 13px;
            }

            .img-new {
                width: 450px;
                border-radius: 13px 0 0 13px;
                height: auto;
                object-fit: cover;
                height: 220px;
                object-position: center;
            }

            .info-box {
                width: calc(100% - 450px);
                padding: 20px 40px;
                background: $blueLight;
            }
        }
    }

    @media screen and (max-width: 1350px) {

        .slider-news-featured .content-new .img-new,
        .slider-news-featured .agile__slide {
            height: 250px;
        }
    }

    @media screen and (max-width: 1060px) {
        .slider-news-featured .content-new .img-new {
            width: 300px;
        }

        .slider-news-featured .content-new .info-box {
            width: calc(100% - 300px);
            padding: 20px 20px;
        }
    }

    @media screen and (max-width:845px) {
        .slider-news-featured .content-new .img-new {
            width: 100%;
            height: 250px;
            border-radius: 13px 13px 0 0;
        }

        .slider-news-featured .content-new .info-box {
            width: 100%;
            padding-bottom: 55px;
        }

        .slider-news-featured .agile__slide {
            height: max-content;
        }

        .slider-news-featured .content-new {
            padding-left: 0;
        }

        .slider-news-featured .content-new .category {
            top: 0;
            border-radius: 13px 0;
        }

    }
</style>