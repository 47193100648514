<template>
    <div class="home">
        <section class="container">

            <sliderNewsFeatured></sliderNewsFeatured>
            <section class="container-grid" v-if="news">
                <div class="grid-big">
                    <template v-for="(notice, index) in Object.values(news).slice(0,3)" :key="index">
                        <newFullImg v-if="index == 0" :dataContent="notice"></newFullImg>
                        <new50Img v-if="index >= 1 && index < 2" :dataContent="notice"></new50Img>

                    </template>
                    <section class="grid-two">
                        <template v-for="(notice, index) in Object.values(news).slice(4,8)" :key="index">
                            <newVerticalImg :dataContent="notice"></newVerticalImg>

                        </template>
                        <template v-for="(notice,index) in Object.values(news).slice(9,15)" :key="index">

                            <newOnlyText :dataContent="notice"></newOnlyText>
                        </template>
                    </section>
                    <div class="row-center border-top">
                        <router-link class="btn-white" :to="{ name: 'news' }" title="Ir a página de Actualidad" alt="Ir a página de Actualidad">Ver más</router-link>
                    </div>
                </div>
                <div class="grid-small">
                    <p class="title-dest-underline" v-if="interviews">Entrevistas</p>
                    <template v-for="(interview, key, index) in interviews" :key="key">
                        <newVerticalImg v-if="index >= 0 && index < 2" :dataContent="interview"></newVerticalImg>

                    </template>
                    <div class="row-center border-bottom" v-if="interviews">
                        <router-link class="btn-white" :to="{ name: 'interviews' }" title="Ir a página de Entrevistas" alt="Ir a página de Actualidad">Ver más</router-link>
                    </div>
                    <p class="title-dest-underline" v-if="coverages">Reportajes</p>
                    <template v-for="(coverage, key, index) in coverages" :key="key">
                        <newVerticalText v-if="index >= 0 && index < 1" :dataContent="coverage"></newVerticalText>

                    </template>
                    <div class="row-center border-bottom" v-if="coverages">
                        <router-link class="btn-white" :to="{ name: 'reports' }" title="Ir a página de Reportajes" alt="Ir a página de Actualidad">Ver más</router-link>
                    </div>
                    <p class="title-dest-underline" v-if="opinions">Opinión</p>
                    <template v-for="(opinion, key, index) in opinions" :key="key">
                        <opinionSmall v-if="index < 3" :dataContent="opinion"></opinionSmall>

                    </template>
                    <div class="row-center border-bottom" v-if="opinions">
                        <router-link class="btn-white" :to="{ name: 'opinion' }" title="Ir a página de Opiniones" alt="Ir a página de Actualidad">Ver más</router-link>
                    </div>
                    <!-- <feedTwitter></feedTwitter> -->
                </div>
            </section>
            <gridContentsFooter></gridContentsFooter>
        </section>
        <section class="banner-only container" v-if="bannerFeatures.length != 0 ">
            <agile :options="myOptions" ref="carousel">
                <template v-for="(banner, key) in bannerFeatures" :key="key">
                    <div class="container">
                        <a :href="banner.url">
                            <img :src="banner.image.url" :alt="banner.title">
                            <img :src="banner.mobile_image.url" :alt="banner.title" class="img-responsive">
                        </a>
                    </div>
                </template>
            </agile>
        </section>
        <section class="sec-suscription container" v-if="banners.length != 0">
            <agile :options="myOptions" ref="carousel">
                <template v-for="(banner, key) in banners" :key="key">
                    <bannerSuscription :dataContent="banner"></bannerSuscription>
                </template>
            </agile>
        </section>
    </div>
</template>

<script>
    // @ is an alias to /src
    import {
        mapGetters,
        mapActions
    } from "vuex";
    import store from "@/store";
    import newFullImg from "@/components/news/new-full-img.vue";
    import new50Img from "@/components/news/new-50-img.vue";
    import newOnlyText from "@/components/news/new-only-text.vue";
    import newVerticalImg from "@/components/news/new-featured-vertical-img.vue";
    import newVerticalText from "@/components/news/new-featured-vertical-text.vue";
    import opinionSmall from "@/components/opinions/view-opinion-small.vue";
    // import feedTwitter from "@/components/feed-twitter.vue";
    import bannerSuscription from "@/components/banner-suscription.vue";
    import sliderNewsFeatured from "@/components/news/slider-news-featured.vue";

    import gridContentsFooter from "@/components/content-footer-dinamic.vue";
    import { VueAgile } from "vue-agile";
    export default {
        name: "Home",
        components: {
            agile: VueAgile,
            newFullImg,
            sliderNewsFeatured,
            new50Img,
            newOnlyText,
            newVerticalImg,
            newVerticalText,
            // feedTwitter,
            opinionSmall,
            bannerSuscription,
            gridContentsFooter,
        },
        props: ['filterTime'],
        data: () => ({
            myOptions: {
                responsive: [{
                    breakpoint: 0,
                    settings: {
                        fade: true,
                        navButtons: false,
                        slidesToShow: 1,
                        dots: true,
                        // autoplay:true
                    },
                }],
            },
        }),
        computed: {
            themes: function () {
                return store.getters["contents/getThemes"];
            },
            themesNormal: function () {
                return store.getters["contents/getThemesNormal"];
            },
            news: function () {
                return store.getters["contents/getNews"](1);
            },
            interviews: function () {
                return store.getters["contents/getInterviews"](1);
            },
            coverages: function () {
                return store.getters["contents/getCoverages"](1);
            },
            opinions: function () {
                return store.getters["contents/getOpinions"](1);
            },
            banners: function () {
                return store.getters["contents/getBanners"];
            },
            bannerFeatures: function () {
                   return store.getters["contents/getBannersFeatures"];
            }
        },
        methods: {
            ...mapActions({
                loadEvenTheThingsWithHandtStolen: "contents/loadEvenTheThingsWithHandtStolen",
                loadBanners: "contents/loadBanners"
            }),
            ...mapGetters({
                getNews: "contents/getNews",
                getInterviews: "contents/getInterviews",
                getCoverages: "contents/getCoverages",
                getOpinions: "contents/getOpinions"
            })
        },
        mounted() {
            this.loadEvenTheThingsWithHandtStolen();
            this.loadBanners();
        },
        watch: {
            filterTime: function (newVal, oldVal) {
                this.loadEvenTheThingsWithHandtStolen();
                this.loadBanners();
            }
        }
    };
</script>
<style>
    .-themes {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding-top: 60px;
        flex-direction: column;
    }

    .container-themes {
        margin-top: 30px;
        justify-content: center;
        align-items: flex-start;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }
</style>